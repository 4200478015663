body {
  margin: 0;
  font-family: 'Neue Montreal', Helvetica, sans-serif;
  background-color: #000;
  color: #fff;
  overflow: hidden;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;

  h1 {
    font-size: 15px;
    font-weight: bold;
    margin: 0;
  }
}

main {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-family: 'Monument Grotesk SemiMono', monospace;
    font-size: 15px;
    margin: 0;

    span {
      display: inline-block;

      &:nth-of-type(1) { transform: translate(-92px, 24px); }
      &:nth-of-type(2) { transform: translate(-4px, -22px); }
      &:nth-of-type(3) { transform: translate(13px, -12px); }
      &:nth-of-type(4) { transform: translate(88px, 13px); }
    }
  }
}

.images-effect {
  position: absolute;
  z-index: -1;
  top: 0; left: 0;
  bottom: 0; right: 0;
  opacity: 0.5;

  img {
    position: absolute;
    width: 200px;
    height: 200px;
    overflow: hidden;
  }
}
